import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutEN from "../../composants/layout/en/LayoutEN"
import { obtenirPage } from "../../js/client-es/utils"

import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageMentionsLegales: allPagesCompilationYaml(
      filter: { code: { eq: "mentionsLegalesEN" } }
    ) {
      ...FragmentPageYaml
    }
  }
`

export default function LegalNotice() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageMentionsLegales")

  return (
    <LayoutEN page={page}>
      <p>By using this website you unreservedly accept these conditions.</p>
      <h3>Author</h3>
      <p>Bastien Foucher</p>
      <p>SIRET : 800 314 817 00027</p>
      <h3>Host</h3>
      <p>
        OVH
        <br />
        2 rue Kellermann - 59100 Roubaix
        <br />
        France
        <br />
        www.ovh.fr
      </p>
      <h3>Personal data</h3>
      <p>
        This site is GDPR compliant: it does not use cookies and does not
        collect individual data. Only total traffic statistics per page are
        produced. However, in the event of a purchase (electronic book,
        photographic print), customer details are kept in order to ensure the
        tracking of orders. They are under no circumstances distributed to third
        parties.
      </p>
      <iframe
        style="border: 0; height: 150px; width: 600px;"
        src="https://matomo.bastienfoucher.com/index.php?module=CoreAdminHome&action=optOut&language=fr&backgroundColor=&fontColor=4f4f4f&fontSize=&fontFamily=Arial%2C%20Helvetica%2C%20sans-serif"
      ></iframe>
      <h3>Responsibility</h3>
      <p>
        Internet users assume full responsibility for their use of this website.
        The author declines all responsibility for the information available on
        the site and the uses that may be made of it. Under no circumstances can
        the author be held liable for damages of any kind, direct or indirect,
        which may result from the use of this site or in connection with this
        site.
      </p>
      <h3>Intellectual property</h3>
      <p>
        This website and all of its content (photos, logos, trademarks and
        information of any kind) are protected by the Intellectual Property
        Code. No reproduction of the articles and images on this site may be
        made without the consent of the author.
      </p>
    </LayoutEN>
  )
}
